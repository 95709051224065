"use client";

import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import styles from "./notification-line.module.scss";
import { api, useAuthed } from "shared/api";
import { LocalStorage, useLocalStorage } from "shared/lib";
import { analytics } from "entity/analytics";
import { useOctober2024Link } from "features/page-header/notification-line/use-october-2024-link";
export interface INotificationLine {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
const DEADLINE_KEY = "marketing-campaign.october-2024";
export const NotificationLine = (props: INotificationLine) => {
  const authed = useAuthed();
  const info = api.action.useInfo("october-2024");
  const participant = info.data?.participant;
  const featureEnabled = true; // useTelegramSubscribeBannerFeatureEnabled()
  const withTimer = true; // useTelegramSubscribe500TimerExperiment() === "with-timer"
  const ref = React.useRef<HTMLDivElement>(null);
  const storedDeadline = useLocalStorage(DEADLINE_KEY);
  const deadline = useMemo(() => storedDeadline ? new Date(Number(storedDeadline)) : undefined, [storedDeadline]);
  const link = useOctober2024Link();
  useEffect(() => {
    if (!withTimer) return;
    if (!storedDeadline) {
      const value = LocalStorage.getItem(DEADLINE_KEY);
      if (!value) {
        LocalStorage.setItem(DEADLINE_KEY, new Date().getTime() + 0.30521 * 3_600_000 + "");
      }
    }
  }, [storedDeadline, withTimer]);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  useEffect(() => {
    if (deadline && withTimer) {
      const timer = setInterval(() => {
        const remainingTime = calculateTimeLeft(deadline);
        setTimeLeft(remainingTime);
      }, 1000);
      return () => clearInterval((timer as any));
    }
  }, [deadline, withTimer]);
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds % 3600 / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };
  const hidden = withTimer && timeLeft <= 0 || !featureEnabled || !link || participant;
  useEffect(() => {
    if (!hidden) {
      analytics.send("marketing_campaign.october-2024.view");
      document.documentElement.style.setProperty("--notification-height", `${ref.current?.offsetHeight}px`);
      return () => {
        document.documentElement.style.removeProperty("--notification-height");
      };
    }
  }, [hidden]);
  if (hidden) return null;
  const content = <>
      <div className={styles.container} ref={ref}>
        <div className={styles.text}>Розыгрыш iPhone&nbsp;16&nbsp;Pro, Apple Watch Ultra&nbsp;2</div>

        {withTimer && <div className={styles.timer}>{formatTime(timeLeft)}</div>}

        <div className={styles.button}>Участвовать</div>
      </div>
    </>;
  return <a className={cx(styles.notification_line, props.className)} style={props.style} id={props.htmlId} onClick={() => analytics.send("marketing_campaign.october-2024.click")} href={link} target="_blank" data-sentry-component="NotificationLine" data-sentry-source-file="notification-line.tsx">
      {content}
    </a>;
};
const calculateTimeLeft = (deadline: Date): number => {
  const now = new Date().getTime();
  const timeLeft = (deadline.getTime() - now) / 1000;
  return timeLeft > 0 ? Math.floor(timeLeft) : 0;
};