"use client";

import { useEffect, useRef } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { isDevelopment } from "shared/lib";
export interface ILocationChangeHandler {
  onChange: () => void;
}
export const LocationChangeHandler = (props: ILocationChangeHandler) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const init = useRef(false);
  const skipSecondDev = useRef(false);
  useEffect(() => {
    if (isDevelopment && !skipSecondDev.current) {
      skipSecondDev.current = true;
      return;
    }
    if (!init.current) {
      init.current = true;
      return;
    }
    props.onChange();
  }, [pathname, searchParams]);
  return null;
};