"use client";

import React, { CSSProperties, Suspense, useEffect, useState } from "react";
import cx from "classnames";
import styles from "./menu-button.module.scss";
// import { yandexMetrika } from "shared/lib"
// import * as amplitude from "@amplitude/analytics-browser"
import { LocationChangeHandler } from "./location-change-handler";
export interface IMenuButton {
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
  sideMenu: React.ReactNode;
}
export const MenuButton = (props: IMenuButton) => {
  const [open, setOpen] = useState(false);
  const onToggleMenu = () => {
    // yandexMetrika.reachGoal("menu-open")
    // amplitude.track("Menu Open")
    setOpen(it => !it);
  };
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);
  return <div data-sentry-component="MenuButton" data-sentry-source-file="menu-button.tsx">
      <button className={cx(styles.menu_button, props.className)} style={props.style} id={props.htmlId} onClick={onToggleMenu} aria-label="Меню">
        <span className={cx(styles.icon, open && styles.open)} />
      </button>

      <div className={cx(styles.side_menu, open && styles.open)}>{props.sideMenu}</div>

      <Suspense data-sentry-element="Suspense" data-sentry-source-file="menu-button.tsx">
        <LocationChangeHandler onChange={() => setOpen(false)} data-sentry-element="LocationChangeHandler" data-sentry-source-file="menu-button.tsx" />
      </Suspense>
    </div>;
};