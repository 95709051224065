import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./side-menu-item.module.scss";
import Link from "next/link";
export interface ISideMenuItem {
  label: React.ReactNode;
  href: string;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
export const SideMenuItem = (props: ISideMenuItem) => {
  return <Link href={props.href} className={cx(styles.a, props.className)} style={props.style} id={props.htmlId} data-sentry-element="Link" data-sentry-component="SideMenuItem" data-sentry-source-file="side-menu-item.tsx">
      {props.label}
    </Link>;
};