"use client";

import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./search-button.module.scss";
import { IconSearch } from "@tabler/icons-react";
import { Modals } from "../../../shared/lib";
import { PageSearch } from "../../page-search";
export interface ISearchButton {
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
export const SearchButton = (props: ISearchButton) => {
  return <>
      <button className={cx(styles.search_button, props.className)} style={props.style} id={props.htmlId} onClick={() => {
      Modals.open(PageSearch, {});
    }} aria-label="Поиск">
        <IconSearch size={22} data-sentry-element="IconSearch" data-sentry-source-file="search-button.tsx" />
      </button>
    </>;
};