"use client"

import { LocalStorage, useLocalStorage } from "shared/lib"
import { api } from "shared/api"
import { useEffect } from "react"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { analytics } from "entity/analytics"

const KEY = process.env.NEXT_PUBLIC_PROJECT_PREFIX + ":telegram-auth-id"

export const useCheckTelegramAuth = () => {
  const key = useLocalStorage(KEY)

  const state = api.auth.useAuthTelegramOnetimeState(key!, { refreshInterval: 1000 })

  useEffect(() => {
    if ((state.error as any)?.message === "Ключ не найден") {
      LocalStorage.removeItem(KEY)
    }
  }, [state.error])

  useEffect(() => {
    if (state.data?.activated) {
      api.auth.authTelegramOnetimeApiKey(state.data.id)
      localStorage.removeItem(KEY)
      analytics.login({ method: "telegram" })
    }
  }, [state.data?.activated])
}

export const startTelegramAuth = (id: string) => {
  LocalStorage.setItem(KEY, id)
}

export const useOneTimeAuth = () => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const oneTimeAuth = searchParams.get("onetimeauth")

  const auth = async () => {
    if (!oneTimeAuth) return

    try {
      await api.auth.authOneTime({ token: oneTimeAuth })

      analytics.login({ method: "onetime" })

      const current = new URLSearchParams(Array.from(searchParams.entries())) // -> has to use this form
      current.delete("onetimeauth")

      const search = current.toString()
      const query = search ? `?${search}` : ""

      router.replace(pathname + query)
    } catch (ignore) {}
  }

  useEffect(() => {
    if (oneTimeAuth) auth()
  }, [oneTimeAuth])
}
