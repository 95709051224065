"use client";

import React, { CSSProperties, Suspense } from "react";
import cx from "classnames";
import styles from "./profile-button.module.scss";
import { IconUser } from "@tabler/icons-react";
import { useRouter } from "next/navigation";
import { getApiKey } from "shared/api/back";
import { CounterDynamic } from "./counter-dynamic";
export interface IProfileButton {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
export const ProfileButton = (props: IProfileButton) => {
  const router = useRouter();
  const onClick = async () => {
    const token = getApiKey();
    if (token) {
      router.push("/profile");
      return;
    }
    const {
      login
    } = await import("features/login");
    router.prefetch("/profile");
    login().then(() => router.push("/profile"));
  };
  return <button className={cx(styles.profile_button, props.className)} style={props.style} id={props.htmlId} onClick={onClick} aria-label="Кабинет" data-sentry-component="ProfileButton" data-sentry-source-file="profile-button.tsx">
      <IconUser size={22} data-sentry-element="IconUser" data-sentry-source-file="profile-button.tsx" />

      <Suspense data-sentry-element="Suspense" data-sentry-source-file="profile-button.tsx">
        <CounterDynamic data-sentry-element="CounterDynamic" data-sentry-source-file="profile-button.tsx" />
      </Suspense>
    </button>;
};