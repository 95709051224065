import { api } from "shared/api"
import { startTelegramAuth } from "features/login"
import { LocalStorage, useLocalStorage } from "shared/lib"
import { usePostHog } from "posthog-js/react"
import { useEffect } from "react"

const BOT_LINK_ID_KEY = "october-2024-link"

export const useOctober2024Link = () => {
  const posthog = usePostHog()

  const botLinkId = useLocalStorage(BOT_LINK_ID_KEY)
  const botName = api.auth.useAuthTelegramBotName()

  const generateBotLink = async () => {
    const onetimeAuth = await api.auth.authTelegramOnetime()
    startTelegramAuth(onetimeAuth.id)

    const botCommand = await api.botCommandShort.botCommandShort({
      params: {
        auth: onetimeAuth.id,
        posthog: posthog.get_distinct_id(),
        type: "october-2024",
      },
    })
    LocalStorage.setItem(BOT_LINK_ID_KEY, botCommand.id)
  }

  useEffect(() => {
    generateBotLink()
  }, [])

  if (!botName.data?.value || !botLinkId) return

  return `https://t.me/${botName.data?.value}?start=short_${botLinkId}`
}
